














































// Core
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Interfaces
import { User, UsersState } from '@store/users/interfaces'
import { UsersActions } from '@store/users/types'
import { Log } from '@store/users/interfaces'
import {Meta, Paginate} from "@store/interfaces";
import { getBaseUrl } from '@/http'

const NSUsers = namespace('usersModule')

@Component({
  name: 'UserLogs',

  components: {
    'v-log-card': () => import('./components/LogCard.vue'),
  },
})

export default class UserComponent extends Vue {
  @NSUsers.State((state: UsersState) => state.user)
  private user!: User

  @NSUsers.Action(UsersActions.A_GET_USER)
  private fetchUser!: (userId: number) => Promise<User>

  @NSUsers.Action(UsersActions.A_GET_LOGS)
  private fetchLogs!: (payload: {page:number, user_id:number}) => Promise<Paginate<Log>>
  private baseUrl: string

  constructor() {
    super();
    this.baseUrl = getBaseUrl();
  }

  private logs: Log[] = []
  private meta!: Meta

  private userId
  private loading: boolean = false

  private async changePage(page) {
    const {data, meta} = await this.fetchLogs({ page, user_id:this.userId })

    this.logs = data
    this.meta = meta
    this.$scrollToTop()
  }

  async created() {
    if(this.user.id){
      this.userId = this.user.id
    } else {
      this.userId = parseInt(this.$route.params.userId, 10)
      await this.fetchUser(this.userId)
    }

    await this.changePage(1)

    this.loading = true
  }


}
